import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useToken from 'hooks/useToken';
import Skeleton from 'react-loading-skeleton';
import { Col, Row, Modal, CloseButton, Button, Table, Form } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import IconButton from 'components/common/IconButton';




const ModalInfoCuenta = (
    {
        showAgregarCuentaModal,
        setshowAgregarCuentaModal,
        tableData,
        //setTableData,
        idCuenta,
        setIDCuenta,

        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo
    }
) => {

    const { token, setToken } = useToken();
    const [cargando, setCargando] = useState(false);

    const [cuenta, setCuenta] = useState({});

    const [editar, setEditar] = useState(false);

    /**
   * Obtiene una cuenta por su ID
   */
    const obtenerCuentaByID = async () => {

        setCargando(true);
        var objDataToSend = {};
        objDataToSend.idCuenta = idCuenta;

        const response = await fetch("/api/cuentas/obtenerCuentaByID",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(objDataToSend)
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
           // console.log("==== OBTENER CUENTA BY ID ====");

            //console.log("response: ", response);
            const data = await response.json();
            //console.log("data CuentaByID: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                //console.log("ObjDetalles CuentaByID:", ObjDetalles);

                setCuenta(ObjDetalles.table2[0]);

                //setActiveStripe(ObjDetalles.table2[0].vStripeSecretKey? true : false);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

            setCargando(false);
        }
        else {
            console.log("Error CuentaByID", response);
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }

            setCargando(false);
        }
    };


    /**
     * Guardar info de la cuenta
     */
    const guardarInfoCuenta = async () => {

        setCargando(true);
        //var objDataToSend = {};
        //objDataToSend.idCuenta = idCuenta;

        const response = await fetch("/api/cuentas/guardarInfoCuenta",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cuenta)
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {
            //console.log("==== OBTENER CUENTA BY ID ====");

            //console.log("response: ", response);
            const data = await response.json();
           // console.log("data CuentaByID: ", data);

            if (data.iResponseType == 1) {

                var ObjDetalles = JSON.parse(data.detalles);

                //console.log("ObjDetalles CuentaByID:", ObjDetalles);

                setCuenta(ObjDetalles.table2[0]);
                setEditar(false);

                if (ObjDetalles.table2[0].bStripeActive) {
                    tableData.forEach((w) => { w.bStripeActive = 0 });
                }

                var currentIndex = tableData.findIndex((acc) => acc.idCatCuentaBancaria === idCuenta);
                if (currentIndex >= 0) {
                    tableData[currentIndex] = ObjDetalles.table2[0];
                }
                else {
                    tableData.push(ObjDetalles.table2[0]);
                }

                setMensajeInformativo("Datos editados");
                setShowMensajeInformativo(true);

                //setActiveStripe(ObjDetalles.table2[0].vStripeSecretKey? true : false);

            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }

            setCargando(false);
        }
        else {
            console.log("Error CuentaByID", response);
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }

            setCargando(false);
        }
    };
    

    useEffect(
        () => {
            console.log("idCuenta", idCuenta)
            if (idCuenta > 0) {
                obtenerCuentaByID();
            } else {
                setEditar(true);
            }
        }
    , []);


    return (
        <>
            <Modal
                show={showAgregarCuentaModal}
                onHide={() => { setshowAgregarCuentaModal(false); setIDCuenta(0); }}
                size="md"
                scrollable
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">

                        <Row>
                            <Col>
                                Cuenta
                                {
                                    cuenta.vStripeSecretKey &&
                                    <>
                                        <SoftBadge
                                            pill
                                            bg={'stripe'}
                                            className="fs--2 ms-1"
                                        >
                                            <span className="mt-1 mb-1">
                                                stripe
                                            </span>
                                        </SoftBadge>

                                        <SoftBadge
                                            pill
                                            bg={cuenta.bStripeActive ? "success" : "danger"}
                                            className="fs--2 ms-1"
                                        >
                                            <span className="mt-1 mb-1">
                                                {cuenta.bStripeActive ? "Vigente" : "No vigente"}
                                            </span>
                                        </SoftBadge>
                                    </>

                                }
                            </Col>
                        </Row>
                        {
                            (idCuenta > 0) &&
                            <>
                                <Row className="mt-2">
                                    <Col>
                                        <IconButton
                                            variant={editar ? "danger" : "falcon-default"}
                                            size="sm"
                                            transform="shrink-2"
                                            className="d-sm-block me-2 ms-2"
                                            icon="x"
                                            onClick={() => { setEditar(!editar); }}
                                        >
                                            {
                                                editar ? "Cancel" : "Editar"
                                            }
                                        </IconButton>
                                    </Col>
                                </Row>
                            </>
                        }
                       

                        

                       
                        

                    </Modal.Title>

                   


                    {cargando ?
                        <Skeleton />
                        :
                        <CloseButton
                            className="btn btn-circle btn-sm transition-base p-0"
                            onClick={() => { setshowAgregarCuentaModal(false); setIDCuenta(0); }}
                        />
                    }

                   
                </Modal.Header>
                <Modal.Body>

                    <Row className="gx-3">
                        <Col>
                            <>
                                <Row>

                                    <Col>

                                        <Table striped className="fs--1">
                                            <tbody>

                                                {
                                                    (idCuenta > 0) &&
                                                        <tr>
                                                            <td width={160}>
                                                                ID
                                                            </td>
                                                            <td className="fw-bold">
                                                                {
                                                                    cargando ?
                                                                        <>
                                                                            <Skeleton />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {cuenta.idCatCuentaBancaria}
                                                                        </>

                                                                }
                                                            </td>
                                                        </tr>
                                                }

                                                <tr>
                                                    <td>
                                                        Número de cuenta *
                                                    </td>
                                                    <td className="fw-bold">
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        editar ?
                                                                            <>
                                                                                <Form.Control
                                                                                    size="sm"
                                                                                    autoComplete="off"
                                                                                    defaultValue={cuenta.vNumCta}
                                                                                    type="text"
                                                                                    onChange={(e) => {
                                                                                        console.log(e.target.value);

                                                                                        setCuenta(prev => ({ ...prev, vNumCta: e.target.value }))

                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {cuenta.vNumCta}
                                                                            </>

                                                                    }

                                                                </>
                                                        }

                                                    </td>
                                                </tr>
                                               
                                                <tr>
                                                    <td>
                                                        Alias *
                                                    </td>
                                                    <td className="fw-bold">
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        editar ?
                                                                            <>
                                                                                <Form.Control
                                                                                    size="sm"
                                                                                    autoComplete="off"
                                                                                    defaultValue={cuenta.vAlias}
                                                                                    type="text"
                                                                                    onChange={(e) => {
                                                                                        console.log(e.target.value);

                                                                                        setCuenta(prev => ({ ...prev, vAlias: e.target.value }))

                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {cuenta.vAlias}
                                                                            </>

                                                                    }
                                                                </>

                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Titular *
                                                    </td>
                                                    <td className="fw-bold">
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        editar ?
                                                                            <>
                                                                                <Form.Control
                                                                                    size="sm"
                                                                                    autoComplete="off"
                                                                                    defaultValue={cuenta.vNombreTitular}
                                                                                    type="text"
                                                                                    onChange={(e) => {
                                                                                        console.log(e.target.value);

                                                                                        setCuenta(prev => ({ ...prev, vNombreTitular: e.target.value }))

                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {cuenta.vNombreTitular}
                                                                            </>

                                                                    }
                                                                    
                                                                </>
                                                        }
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td>
                                                        Clabe *
                                                    </td>
                                                    <td className="fw-bold">
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        editar ?
                                                                            <>
                                                                                <Form.Control
                                                                                    size="sm"
                                                                                    autoComplete="off"
                                                                                    defaultValue={cuenta.vClabe}
                                                                                    type="text"
                                                                                    onChange={(e) => {
                                                                                        console.log(e.target.value);

                                                                                        setCuenta(prev => ({ ...prev, vClabe: e.target.value }))

                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {cuenta.vClabe}
                                                                            </>

                                                                    }
                                                                </>
                                                        }
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Banco *
                                                    </td>
                                                    <td className="fw-bold">
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        editar ?
                                                                            <>
                                                                                <Form.Control
                                                                                    size="sm"
                                                                                    autoComplete="off"
                                                                                    defaultValue={cuenta.vNombreBanco}
                                                                                    type="text"
                                                                                    onChange={(e) => {
                                                                                        console.log(e.target.value);

                                                                                        setCuenta(prev => ({ ...prev, vNombreBanco: e.target.value }))

                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {cuenta.vNombreBanco}
                                                                            </>

                                                                    }
                                                                </>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Código Swift *
                                                    </td>
                                                    <td className="fw-bold">
                                                        
                                                        {
                                                            cargando ?
                                                                <>
                                                                    <Skeleton />
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        editar ?
                                                                            <>
                                                                                <Form.Control
                                                                                    size="sm"
                                                                                    autoComplete="off"
                                                                                    defaultValue={cuenta.vCodigoSwift}
                                                                                    type="text"
                                                                                    onChange={(e) => {
                                                                                        console.log(e.target.value);

                                                                                        setCuenta(prev => ({ ...prev, vCodigoSwift: e.target.value }))

                                                                                    }}
                                                                                />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {cuenta.vCodigoSwift}
                                                                            </>

                                                                    }
                                                                    
                                                                </>
                                                        }
                                                    </td>
                                                </tr>

                                                {
                                                    (cuenta.vStripeSecretKey || editar) ?
                                                    <>
                                                        <tr>
                                                            <td>
                                                                Stripe Secret Key
                                                            </td>
                                                            <td className="fw-bold">
                                                                {
                                                                    cargando ?
                                                                        <>
                                                                            <Skeleton />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                editar ?
                                                                                <>
                                                                                    <Form.Control
                                                                                        size="sm"
                                                                                        autoComplete="off"
                                                                                        defaultValue={cuenta.vStripeSecretKey}
                                                                                        type="text"
                                                                                        onChange={(e) => {
                                                                                            console.log(e.target.value);

                                                                                            setCuenta(prev => ({ ...prev, vStripeSecretKey: e.target.value }))

                                                                                        }}
                                                                                    />
                                                                                </>
                                                                                :
                                                                                <>
                                                                                        {cuenta.vStripeSecretKey}
                                                                                </>
                                                                                    
                                                                            }
                                                                            
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Vigente Stripe
                                                            </td>
                                                            <td className="fw-bold">
                                                                {
                                                                    cargando ?
                                                                        <>
                                                                            <Skeleton />
                                                                        </>
                                                                        :
                                                                        <>
                                                                                {
                                                                                    editar ?
                                                                                        <>
                                                                                            <Form.Check // prettier-ignore
                                                                                                type="switch"
                                                                                                id="custom-switch"
                                                                                                label={cuenta.bStripeActive ? "Vigente" : "No vigente"}
                                                                                                defaultChecked={cuenta.bStripeActive}

                                                                                                onChange={(e) => {
                                                                                                    console.log(e.target.checked);

                                                                                                    setCuenta(prev => ({ ...prev, bStripeActive: e.target.checked }))
                                                                                                    
                                                                                                }}
                                                                                
                                                                                            />
                                                                                           
                                                                                            
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                cuenta.bStripeActive ? 
                                                                                                  
                                                                                                    <>
                                                                                                        <SoftBadge
                                                                                                            pill
                                                                                                            bg={'stripe'}
                                                                                                            className="fs--2 ms-1"
                                                                                                        >
                                                                                                            <span className="mt-1 mb-1">
                                                                                                                Vigente
                                                                                                            </span>
                                                                                                        </SoftBadge>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <SoftBadge
                                                                                                            pill
                                                                                                            bg={'danger'}
                                                                                                            className="fs--2 ms-1"
                                                                                                        >
                                                                                                            <span className="mt-1 mb-1">
                                                                                                                No Activo
                                                                                                            </span>
                                                                                                        </SoftBadge>
                                                                                                    </>
                                                                                            }
                                                                                        </>

                                                                                }
                                                                        </>
                                                                }
                                                            </td>
                                                            </tr>

                                                            {
                                                                cuenta.bStripeActive &&
                                                                <tr>
                                                                <td colSpan={2 }>
                                                                        <span className="fs--1"> <strong>Nota.-</strong> Solo una cuenta en Stripe puede estar Vigente, por lo que si hay alguna otra vigente, se desactivará de manera automatica.</span>
                                                                </td>
                                                             
                                                            </tr>
                                                                
                                                            }
                                                            
                                                    </>
                                                    :
                                                    <>
                                                            <tr>
                                                                <td colSpan={2 }>
                                                                   

                                                                   
                                                                </td>
                                                             
                                                            </tr>

                                                          
                                                    </>
                                                }
                                                
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>

                            </>
                        </Col>
                    </Row>

                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-center">
                    {
                        cargando ?
                            <>
                                <Skeleton />
                            </>
                            :
                            <>
                                <Row className="d-flex align-items-center">

                                    <Col>
                                        <Button
                                            onClick={() => { setshowAgregarCuentaModal(false); setIDCuenta(0); }}
                                            variant='danger'
                                        >
                                            Cerrar
                                        </Button>
                                    </Col>

                                    <Col>

                                        {
                                            editar &&
                                            <Button
                                                onClick={() => {
                                                    //setshowAgregarCuentaModal(false);
                                                    //setIDCuenta(0);

                                                    guardarInfoCuenta();
                                                }}
                                                variant='primary'
                                            >
                                                Guardar
                                            </Button>
                                        }

                                       

                                    </Col>
                                    

                                   
                                </Row>
                            </> 
                    }
                </Modal.Footer>



            </Modal>

            
        </>

    );
};

ModalInfoCuenta.propTypes = {
    showAgregarCuentaModal: PropTypes.bool,
    setshowAgregarCuentaModal: PropTypes.func,
    tableData: PropTypes.array,
    //setTableData: PropTypes.func,
    idCuenta: PropTypes.number,
    setIDCuenta: PropTypes.func,

    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func
};

export default ModalInfoCuenta;
