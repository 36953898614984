
import useToken from 'hooks/useToken';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { Card, Col, Row, Spinner } from 'react-bootstrap';

import IconButton from 'components/common/IconButton';


import ModalRegistroGasto from './ModalRegistroGasto';


const ListaGastos = (
    {
        setMensajeError,
        setShowMensajeError,
        setMensajeInformativo,
        setShowMensajeInformativo
    }

) => {


    const { token, setToken } = useToken();

    const [gastos, setGastos] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [showModalRegistrarGasto, setShowModalRegistrarGasto] = useState(false);


    const [showModalNuevo,setShowModalNuevo ] = useState(false);

    const [selectedGasto, setSelectedGasto] = useState({});
    
   

    /**
    * Se ejecuta la primera vez
    */
    useEffect(() => {
        ObtenerDatosGastos()


    }, []);

    /**
     * Convertir Base64 a archivo
     */
    const base64ToFile = (base64String, fileName) => {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        return new File([blob], fileName, { type: blob.type });
    };


    /**
     * Seleccion del row
     */
    const seleccionRow = (datosGastos) => {
        if (datosGastos.vNotaVenta_Name) {
            // Solo se ejecuta si existe `vNotaVenta_Name`
            var file_Nota = base64ToFile(datosGastos.vNotaVenta_Base64, datosGastos.vNotaVenta_Name);

            let datoGasto_ = { ...datosGastos }; // Crear copia para evitar mutaciones directas
            datoGasto_.fileNota = file_Nota;

            setSelectedGasto(datoGasto_);


        } else {

            setSelectedGasto(datosGastos);
        }
    };


    const columns = [
        {
            accessor: 'none',
            Header: 'ID',
            Cell: rowData => {
                const { idGasto } = rowData.row.original;
                return (
                    <span
                        style={{
                            color: '#2c7be5', // Azul cielo
                            textDecoration: 'none', // Sin subrayado
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            seleccionRow(rowData.row.original); // Guardar datos de la fila
                            setShowModalRegistrarGasto(true); // Mostrar el modal
                            setShowModalNuevo(false);
                        }}
                    >
                        {idGasto}
                    </span>
                );
            }
        },
        {
            accessor: 'vDescripcion',
            Header: 'Descripción',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vDescripcion } = rowData.row.original;
                return <span className=" text-dark">{vDescripcion}</span>; // Negritas y color oscuro
            }
        },
        {
            accessor: 'vResponsableName',
            Header: 'Responsable',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vResponsable } = rowData.row.original;
                return <span className="text-primary">{vResponsable}</span>; // Texto azul
            }
        },
        {
            accessor: 'dFechaGasto',
            Header: 'Fecha',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { dFechaGasto } = rowData.row.original;

                // Formatear la fecha
                const formattedDate = new Date(dFechaGasto).toLocaleDateString("es-MX", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric"
                });

                // Transformar la primera letra del mes a mayúscula
                const formattedWithCapitalMonth = formattedDate.replace(
                    /(?:^|\s)([a-z])/g,
                    char => char.toUpperCase()
                );

                return <span className="text-secondary">{formattedWithCapitalMonth}</span>; // Texto gris
            }
        },
        {
            accessor: 'idTipoGasto',
            Header: 'Tipo de Gasto',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vTipoGasto } = rowData.row.original;
                return <span className="text-primary">{vTipoGasto}</span>; // Etiqueta estilizada
            }
        },
        {
            accessor: 'idCatFormaPago',
            Header: 'Forma de Pago',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { vFormaPago } = rowData.row.original;
                return <span className="">{vFormaPago}</span>; 
            }
        },
        {
            accessor: 'dTotal',
            Header: 'Total',
            cellProps: { className: 'ps-1' },
            Cell: rowData => {
                const { dTotal } = rowData.row.original;
                return (
                    <span className="">
                        {dTotal.toLocaleString("es-MX", { style: "currency", currency: "MXN" })}
                    </span>
                );
            }
        }
    ];


    /**
     * Obtiene los Datos de los Gastos
     */
    const ObtenerDatosGastos = async () => {


        setCargando(true)


        const response = await fetch("/api/Gastos/ObtenerDatosGastos",
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            })
            .catch((error) => {
                setShowMensajeError(true);
                setMensajeError('Hubo un problema con la peticion Fetch:' + error);
                console.log('Hubo un problema con la peticion Fetch:' + error);

            });

        if (response.ok) {

            console.log("==== OBTENER DATOS GASTOS ====");

            const data = await response.json();

            if (data.iResponseType == 1) {


                var ObjDetalles = JSON.parse(data.detalles);

                console.log("obtenerDatosGastos", ObjDetalles.table2);


                setGastos(ObjDetalles.table2); // Si table2 es un objeto y quieres envolverlo en un array



            } else {
                setShowMensajeError(true);
                setMensajeError(data.vMessage);
            }
            setCargando(false);



        }
        else {
            setCargando(true);
            setShowMensajeError(true);
            setMensajeError(response.status + ' ' + response.statusText);
            if (response.status == 401) {
                setToken(null);
                location.href = "";
            }
        }
    };










    return (
        <>
         
            <Card className="mb-1 p-2">
                <Row className="g-2 align-items-sm-center">
                    <Col xs="auto">
                    {/*    <img src="/img/cliente96.png" alt="connectCircle" height={30} />*/}
                    </Col>
                    <Col>
                        <h5>
                            Lista de Gastos
                        </h5>
                    </Col>
                    <Col className="col-auto">
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-2"
                            className="d-sm-block"
                            onClick={() => { setShowModalRegistrarGasto(true); setShowModalNuevo(true); }}

                        >
                            Nuevo Gasto
                        </IconButton>
                    </Col>           
                    
                </Row>
            </Card>
            <Row>
                <Col>
                    <AdvanceTableWrapper
                        columns={columns}
                        data={gastos}
                        sortable
                        pagination
                        perPage={15}
                        
                    >

                       

                        <Card className="mb-3">
                            <Card.Header >

                                
                                
                                <Row>
                                    <Col className="ms-auto" sm={8} lg={4} xs={12} >
                                        {!cargando &&
                                            <AdvanceTableSearchBox table />
                                        }
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="p-0">

                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        size: 'sm',
                                        striped: true,
                                        className: 'fs--2 mb-0 overflow-hidden'
                                    }}
                                />
                            </Card.Body>
                            <Card.Footer>
                                <AdvanceTableFooter
                                    rowCount={gastos.length}
                                    table
                                    rowInfo
                                    navButtons
                                    rowsPerPageSelection
                                />
                            </Card.Footer>
                        </Card>
                    </AdvanceTableWrapper>
                    {cargando && (<h5><Spinner className="text-inline" animation="border" variant="primary" /> Cargando...</h5>)}
                </Col>     
            </Row>

            
            {
                showModalRegistrarGasto &&
                <>
                    <ModalRegistroGasto

                        gastos={gastos}
                        setGastos={setGastos}

                        showModalRegistrarGasto={showModalRegistrarGasto}
                        setShowModalRegistrarGasto={setShowModalRegistrarGasto}

                        showModalNuevo={showModalNuevo}
                        setShowModalNuevo={setShowModalNuevo}


                        selectedGasto={selectedGasto}
                        setSelectedGasto={setSelectedGasto}

                        setMensajeError={setMensajeError}
                        setShowMensajeError={setShowMensajeError}
                        setMensajeInformativo={setMensajeInformativo}
                        setShowMensajeInformativo={setShowMensajeInformativo}

                    />
                </>

            }


            


        </>
    );
};

ListaGastos.propTypes = {
    setMensajeError: PropTypes.func,
    setShowMensajeError: PropTypes.func,
    setMensajeInformativo: PropTypes.func,
    setShowMensajeInformativo: PropTypes.func

};


export default ListaGastos;